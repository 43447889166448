// @layer base {
* {
  @apply font-sans;
  // outline: 1px solid red !important;
}

html {
  font-size: 16px;
  min-width: 360px;
  @apply font-sans bg-white  text-gray-50 box-border;

  @screen sm {
    font-size: 18px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold;
}

h1,
h2,
h3 {
  @apply mt-6 mb-3;
}

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl;
}

h4 {
  @apply text-xl;
}

small {
  @apply text-sm;
}

img {
  @apply max-w-full h-auto;
}

p {
  @apply font-sans mb-2;
  &:last-child {
    @apply mb-0;
  }
}

.btn {
  @apply justify-center uppercase cursor-pointer flex items-center px-4 py-2 border border-transparent text-base sm:text-sm font-medium rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
  &[disabled],
  &.disabled {
    @apply text-gray-300 bg-white border border-gray-300 pointer-events-none;
  }
}

.btn-default {
  @apply text-white bg-primary hover:bg-red-600;
  &:active,
  &.active {
    @apply bg-secondary text-white focus:ring-secondary;
  }
}

.btn-cancel {
  @apply text-gray-700 bg-white border border-gray-300 hover:text-gray-500 focus:ring-gray-300;
}

.btn-warning {
  @apply bg-gray-700 text-white hover:bg-gray-600 focus:ring-gray-700;
}

.grayscale {
  filter: grayscale(1);
}

.table {
  @apply w-full;
}

// modules\system\system.theme.css
tbody {
  @apply border-0 border-none;
}
th {
  @apply border-0 border-none;
}
// }
